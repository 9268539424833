import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import CheckDigitCalculator from './CheckDigitCalculator'
import RandomVRNs from './RandomVRNs'
import VRNValidator from './VRNValidator'

export default function App() {
  return (
    <Container>
      <h1 className="mt-3">VRN Toolkit</h1>
      <p>
          Basic utilities for VAT registration numbers in the UK.
          Algorithms and logic sourced from the <a href="https://en.wikipedia.org/wiki/VAT_identification_number" target="_blank" rel="noreferrer">Wikipedia</a> page detailing how VRNs are constructed.
      </p>

      <Row>
        <Col xs={12} md={6}>
          <RandomVRNs />
        </Col>
        <Col xs={12} md={6}>
          <VRNValidator />
        </Col>
        <Col xs={12} md={6}>
          <CheckDigitCalculator />
        </Col>
      </Row>
    </Container>
  )
}
