import React, { FormEvent, useState } from 'react'
import { validateVrn, VrnValidationResult } from '@lilopkins/generate-vrn'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

type ValidateResult = {
  result: VrnValidationResult,
  vrn: string,
}

const typeDisplay = {
  'standard': 'Standard',
  'commercial-group': 'Commercial Group',
  'government-department': 'Governmental Department',
  'health-authority': 'Health Authority',
  'unknown': 'Unknown',
}

const algorithmDisplay = {
  '97': '97',
  '9755': '97-55',
  'n/a': 'Not Applicable to this VRN type',
}

export default function VRNValidator() {
  const [validateResult, setValidateResult] = useState<ValidateResult>()

  const validate = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    const data = new FormData(ev.currentTarget)
    const vrn = data.get('vrn')?.toString().trim()
    if (vrn === undefined) return
    const res: ValidateResult = {
      result: validateVrn(vrn),
      vrn,
    }
    setValidateResult(res)
  }

  return (
    <div className='mb-3'>
      <h2>Validate VRN</h2>

      <form onSubmit={validate}>
        <div className='mb-3'>
          <Form.Label htmlFor='vrn'>VAT Registration Number</Form.Label>
          <Form.Control type='text' id='vrn' name='vrn' />
        </div>
        <Button variant='primary' type='submit'>Validate</Button>
      </form>

      {validateResult !== undefined && (
        <Alert variant={validateResult.result.valid ? 'success' : 'danger'} className='mt-3'>
          Validation Results for:
          {' '}
          <code>{validateResult.vrn}</code>

          <dl>
            <dt>Type</dt>
            <dd>{ typeDisplay[validateResult.result.type] }</dd>

            {validateResult.result.algorithm !== 'n/a' && (
              <>
                <dt>Algorithm</dt>
                <dd>{ algorithmDisplay[validateResult.result.algorithm] }</dd>
              </>
            )}

            <dt>Isle of Man?</dt>
            <dd>{ validateResult.result.isleOfMan ? 'Yes' : 'No' }</dd>

            <dt>Valid?</dt>
            <dd>{ validateResult.result.valid ? 'Yes' : 'No' }</dd>
          </dl>
        </Alert>
      )}
    </div>
  )
}
