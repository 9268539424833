import React, { useMemo, useState } from 'react'
import { calculateCheckDigits97, calculateCheckDigits9755, VrnAlgorithm } from '@lilopkins/generate-vrn'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

export default function CheckDigitCalculator() {
  const [algorithm, setAlgorithm] = useState<VrnAlgorithm>('97')
  const [flash, _setFlash] = useState<string>()
  const [inputVrn, setInputVrn] = useState<string>('')

  const setFlash = (flash: string) => {
    _setFlash(flash)
    setTimeout(() => _setFlash(undefined), 500)
  }

  const result = useMemo(() => {
    if (!/^[0-9]{7}$/.test(inputVrn)) return undefined

    const digits = inputVrn.split('').map(n => Number(n))
    if (algorithm === '97') {
      return calculateCheckDigits97(digits).join('')
    } else if (algorithm === '9755') {
      return calculateCheckDigits9755(digits).join('')
    }
  }, [algorithm, inputVrn])

  const copy = async (val: string) => {
    await navigator.clipboard.writeText(val)
    setFlash('Copied!')
  }

  return (
    <div>
      <h2>Check Digits Calculator</h2>
      <div className='mb-3'>
        <Form.Label htmlFor='algorithm' className='mt-2'>Algorithm:</Form.Label>
        <Form.Select id='algorithm' defaultValue={algorithm} onChange={e => setAlgorithm(e.currentTarget.value as VrnAlgorithm)}>
          <option value='97'>97 (older)</option>
          <option value='9755'>97-55 (newer)</option>
        </Form.Select>

        <Form.Label htmlFor='input' className='mt-2'>Partial VRN (first 7 digits):</Form.Label>
        <Form.Control type='text' id='input' pattern='^[0-9]{7}$' defaultValue={inputVrn} onChange={e => setInputVrn(e.currentTarget.value)} />

        <div className='mt-3'>
          {result === undefined
            ? (
              <span>Invalid VRN format. Please ensure you enter only 7 digits.</span>
            )
            : (
              <>
                <span>Completed VRN: <code>{result}</code></span>
                {' '}
                <Button variant='secondary' size='sm' onClick={() => copy(result)}>Copy</Button>
              </>
            )}
        </div>
        <div className='mb-3'>{flash}&nbsp;</div>
      </div>
    </div>
  )
}
