import React, { useEffect, useState } from 'react'
import { generateVrn, VrnAlgorithm, VrnGenerationOptions, VrnType } from '@lilopkins/generate-vrn'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

export default function RandomVRNs() {
  const [randomVrns, setRandomVrns] = useState<string[]>([])
  const [flash, _setFlash] = useState<string>()
  const [vrnGenerateOptions, setVrnGenerateOptions] = useState<VrnGenerationOptions>({
    type: 'standard',
    algorithm: '9755',
    isleOfMan: false,
  })

  const setFlash = (flash: string) => {
    _setFlash(flash)
    setTimeout(() => _setFlash(undefined), 500)
  }

  const generateRandomVrns = () => {
    const vrns = ['','','',''].map(() => generateVrn(vrnGenerateOptions))
    setRandomVrns(vrns)
  }

  useEffect(generateRandomVrns, [vrnGenerateOptions])

  const copy = async (val: string) => {
    await navigator.clipboard.writeText(val)
    setFlash('Copied!')
  }

  return (
    <div>
      <h2>Random VRNs</h2>
      <div className='mb-3'>
        <Form.Label htmlFor='type'>Type:</Form.Label>
        <Form.Select id='type' defaultValue={vrnGenerateOptions.type} onChange={e => setVrnGenerateOptions({ ...vrnGenerateOptions, type: e.currentTarget.value as VrnType })}>
          <option value='standard'>Standard</option>
          <option value='commercial-group'>Commercial Group</option>
          <option value='government-department'>Governmental Department</option>
          <option value='health-authority'>Health Authority</option>
        </Form.Select>
        {(vrnGenerateOptions.type === 'standard' || vrnGenerateOptions.type === 'commercial-group') && (
          <>
            <Form.Label htmlFor='algorithm' className='mt-2'>Algorithm:</Form.Label>
            <Form.Select id='algorithm' defaultValue={vrnGenerateOptions.algorithm} onChange={e => setVrnGenerateOptions({ ...vrnGenerateOptions, algorithm: e.currentTarget.value as VrnAlgorithm })}>
              <option value='97'>97 (older)</option>
              <option value='9755'>97-55 (newer)</option>
            </Form.Select>
            <Form.Check type='switch' label='Isle of Man' className='mt-2' defaultChecked={vrnGenerateOptions.isleOfMan} onChange={e => setVrnGenerateOptions({ ...vrnGenerateOptions, isleOfMan: e.currentTarget.checked })} />
          </>
        )}
        <Button variant='primary' className='mt-2' onClick={generateRandomVrns}>Generate new VRNs</Button>
      </div>

      <div className='mb-3'>
        {randomVrns.map(vrn => (
          <span className='me-4' key={vrn}>
            <code className='me-1'>{vrn}</code>
            <Button variant='secondary' size='sm' onClick={() => copy(vrn)}>Copy</Button>
          </span>
        ))}
      </div>
      <div className='mb-3'>{flash}&nbsp;</div>
    </div>
  )
}
